//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      newImg: require("../../assets/err/404.png"),
    };
  },
};
